// ------------------------------------------------------------
// portfolio.js
// ------------------------------------------------------------

import AzzulePng from './img/azzule-example.png';
import CandorJpg from './img/candor-coach-app-store-banner.jpg';
import MolecularMp4 from './vid/molecular.mp4';
import GelJpg from './img/blue-gel-background.jpg';
import MolrevPng from './img/molrev-screenshot.png';
import PatentPng from './img/patent-screenshot.png';
import PatentMobilePng from './img/patent-screenshot-mobile.png';
import React from 'react';

// ------------------------------------------------------------

const AdobeText = [
    "I built out and managed global data centers that ran Adobe's early online services such as Photoshop.com which eventually became the Creative Cloud. I and a few others were awarded a patent based on our work building an internal private cloud.",
];

const MolecularText = [
    "We were early innovators in bringing bioinformatics and life sciences software online. Our service included advanced PCR program optimization and primer design, a plasmid visual explorer, and a variety of other utilities.",
    "At the time we needed something more powerful than what the browser offered natively so we built it using ActionScript. Flash is no longer supported by today's browsers so I've included some old screenshots and videos showing select functionality.",
];

const PatentName = "Dynamic Visualization of Physical and Geographical Multitenant Cloud Computing";
const ScreenshotText = "Screenshot of the homepage and promotional banner videos of the plasmid explorer functionality";

// ------------------------------------------------------------

const parseInfo = ( sentences ) => {
    return (
        <>
            {sentences.map( sentence => {
                return <div key={sentence} className="portfolioSentence">{sentence}</div>;
            } )}
        </>
    );
};

// ------------------------------------------------------------

const Portfolio = ( ) => {
    const title = '{ PORTFOLIO }';

    const openPatent = ( ) => {
        window.open( 'https://patents.google.com/patent/US20130346899', '_blank' );
    };

    // ------------------------------------------------------------

    return (
        <div className="linksDiv" id="links-container">
            <div className="scrollAnchor" id="links-div"></div>
            <div className="titles">{title}</div>

            {/* ------------------------------------------------------------ */}

            <div className="portfolioRow">
                <div className="portfolioRowTitle">Adobe Systems</div>
                <div className="portfolioRowText">{ parseInfo( AdobeText ) }</div>
                <div className="portfolioInfotainer onlyDesktop">
                    <div className="portfolioScreenshot">
                        <img className="portfolioScreenRaised cursorPointer" src={PatentPng} alt="" onClick={openPatent}/>
                    </div>
                    <div className="portfolioDescription cursorPointer" onClick={openPatent}>{PatentName}</div>
                </div>
                <div className="portfolioInfotainer onlyMobile">
                    <img className="portfolioScreenRaised cursorPointer" src={PatentMobilePng} alt="" onClick={openPatent}/>
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="portfolioRow">
                <div className="portfolioRowTitle">Molecular Revolution</div>
                <div className="portfolioRowText">{ parseInfo( MolecularText ) }</div>
                <div className="portfolioWideContainer" style={{ backgroundImage: `url(${GelJpg})` }}>
                    <div className="portfolioInfotainer onlyDesktop">
                        <div className="portfolioScreenshot">
                            <img className="portfolioScreenRaised" src={MolrevPng} alt="" />
                        </div>
                        <div className="portfolioDescription portfolioWhite">{ScreenshotText}</div>
                    </div>
                    <div className="portfolioInfotainer onlyMobile">
                        <img className="portfolioScreenRaised" src={MolrevPng} alt="" />
                    </div>
                    <div className="molrevVideoBanner">
                        <video id="molecular-video" className="videoBanner" autoPlay loop muted>
                            <source id="molecular-source" src={MolecularMp4} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="onlyMobile" style={{ height : 60 }}></div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="portfolioRow">
                <div className="portfolioRowTitle">Azzule</div>
                <div className="portfolioRowText">
                    <div className="portfolioSentence">For Azzule I designed and produced Tufte inspired data dense visualizations as well as interactive data visuals using Tableau and D3.js. Shown below is an example Tufte visual which allowed suppliers and retailers to explore what was going on in their produce supply chains and discover actionable insights.</div>
                    <div className="portfolioSentence">I also have an interactive anonymized data visual and presentation using Backbone.js and D3.js that I used to help evangelize the power of supply chain data <a className="linksDescription" href="https://robertmccone.com/pgfs" target="_blank" rel="noopener noreferrer">which you can explore by clicking here.</a></div>
                </div>
                <div className="portfolioSingle">
                    <img className="portfolioScreenRaisedFull" src={AzzulePng} alt="" />
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="portfolioRow">
                <div className="portfolioRowTitle">Candor, Inc.</div>
                <div className="portfolioRowText">At Candor I built web and mobile apps using React.js and React Native which were released and used by companies wishing to implement a culture of Radical Candor. Candor has since pivoted away from software and have shutdown and removed all apps.</div>
                <div className="portfolioWideContainer candorBkg" style={{ backgroundImage: `url(${CandorJpg})` }}>
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

        </div>
    );
}

// ------------------------------------------------------------

export default Portfolio;

// ------------------------------------------------------------
