// ------------------------------------------------------------
// headSvg.js - TODO add shadow in Ai and reupload/parse 
// ------------------------------------------------------------

import React from 'react';

// ------------------------------------------------------------

const HeadSvg = ({
    size = '100%',
}) =>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	width={size} height={size} viewBox="0 0 60 60" enableBackground="new 0 0 60 60" xmlSpace="preserve">
<g>
	<g>
		<defs>
			<circle id="SVGID_1_" cx="30" cy="30" r="29.826"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlinkHref="#SVGID_1_"  overflow="visible"/>
		</clipPath>
	</g>
	<g>
		<defs>
			<circle id="SVGID_3_" cx="30" cy="30" r="29.826"/>
		</defs>
		<clipPath id="SVGID_4_">
			<use xlinkHref="#SVGID_3_"  overflow="visible"/>
		</clipPath>
		<g clipPath="url(#SVGID_4_)">
			<rect x="0.173" y="0.173" fill="#325688" width="59.653" height="59.654"/>
			<g>

					<image overflow="visible" opacity="0.3" width="345" height="348" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAAFiCAYAAAC+k7DMAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAN/5JREFUeNrsnYtu7DqMbKlO/v+P
t3VxgTlAxmOJVSTlVxeBIJ1OP21puVSiKDOFQqFQKBQKhUKhUCgUCoVCoVAoFAqFQqFQKBQKhUKh
UCgUCoVCoVAoFAqFQqFQKBQKhUKhUCgUCgUaTYdAoTYLRdehVwi8CrXNe4SArBB4FWqHgrFCDV6h
ULsTiBUCr0LtTCBWqEMo1Lbu9x5nwVEQVudQKG7Znu7WTvvDXlch8CrUhl7ZHvtNX0sh8CrUdr6m
/fWbvY5C4FV8aXt5Mqz7w5+vEHgVX9RO2svbZj/pOQKwOpRC7aP0ue3m7bYvemwGpAKwwKtQu1gC
2zu3z37R4wRggVeh9gA/pxW+910Vbz/pdQRggVch4C6D7dkwrrYJevK9BGB1OIXaQBqWZ4LYe84K
L7Yv+J8ArE6nEHBLgdsWvNfZNkMvBG01gAVfgVfx0PMehSBzfwTAZ7TdKnj2gtcQgAVehYCbBmvm
vrPabxaYWQhXAljwFXgVNz7XEeBG78vC907g7eTf2fsEYIFX8QUqtwqu7N93A28FdCuhHIGq4Cvw
Km6uciuAeyaM2e8egRkDUuZ/WbtC8BV4FQ+HboVVgMK3CrzZ9hz1biOgXaWKBWCBV/ElwEWhugLK
EcUeUboRoFY/hwWw4CvwKl4I3dW3K5RxVvFm4NmDz7sCwIKvwKtYdC6vBi563+wx3nOY48IM1bPA
rQYzA2SpX4FXcXOVWwXc2X3Z11mhdlngHgGUBW4FjBn1K/gKvIqLoVuhchG4sgCOwvdM8CIwZR6z
CsCyHgRexY2thZXAjTw2a03cBbrR31EoC74Cr+Lh0EXhe/bv0X1ng3f/Pwau1SCOAljwFXgVi6Eb
UbnVwK14DKp+K+AbVbfs/1aAWPAVeBUPge4ZoM3chyhx5ngxWQJR8CK3owBeOREn+Aq8ikXQzahc
9H/I/6PqGL2wXKF0q26P3iMK4hlEVxRkVwi8gi4I3Qo1i95mgF0NYDZXl1Gs+9ve36P/sWp4dJ8H
Y8FX4FUQ5ygyiVahbivAGoG1B9+VircCthkge5/HA3KV9SD4CryCrnO/5+dm1C0D3BF0mdfZPwZV
vkaCpMJOmN2H3r9CBQu+Aq/iROiy1kJU0c4AOwMvo4ZZy6Fa7UaVbScfgyhhFMKCr8CruCl0o8oW
BSv6GPT9rwRvBrZXQVjwvUn86hC8EtYMdNmsgwhEmR8E6oj1MDo23qQSA94K2B691tHnR1O+2u72
6L6j1z16H++90c+mEHhfp3YroMtOgl3xg1w0mGMZzWJgFOu2AMjt4PO2g9tHUI7AVyHwCrokdPf3
ZRQuCuLP5DmfIjU8+j6z4+QNpZlJNfSnkSDegDbAVBlrzm8EvlK9J3d0xTugy6rdrML9gLCNQNgc
Fcy07dXQHYF2Ix4b9YePPvvs95ENgy68QP+vkOJ9PJRXQ3f0Pw+WH+LvavWL2g2Z9DEWuu0ArO0A
tNv/HA9WAXtqN6N8WWUr5SvwvkbtrobuSmX7IW+jQJ5dQDz4zuyGSBbDBtoM2wFw2+A2C2MEeBH4
RibbFALvK+2eauiy8GXh+gH+V2E9oKq3Wu1+JgBuA+j+hezovfbQ/Qvbj2HZEP3gu6+Gr8As8D4e
upFteCLQZSfKPIh+yN9RCyJiN7DgRdXu5wC+bQDgo999AuQZhBnLoRK+XrsVfAXeV0A5umiiMhWM
UbGfEwAcAe8evih4EbW7h28Dfo8UsB1AdztQvAiEV8BXcBV4X6d2MxkOrRi6USXrAbgaviPwtgmM
LGgv9APg/oXkRgC3OUr46HPtbYeV8J0pWVkOAq983WLoosAd3YdAuAK+I9WLHOeM2v0L3r3i/ex8
XQ+4I9jaQPnaQAFvBfBlASr4CryvtxiYHXuj0D2a8GIU7ehvFr6VqpeFLwrdzwGA9/DdA3h/ew9f
m9w+eg8WwDP42gK/VyHwPs5iiBa6qYAuAlsEsN7/WPXLwDdqNbALI/6Cd6949wD+7GDbBn/voWuO
B8zYD313bEbQrfB7BWqB91XgRorFZKCLWggMdCMAjmQ5oIoXTSHbSPAeKd4RdNsBYEf37W2II4XN
eL+M7yv4CrxfrXZHEF6pcqtgy0IYXajB+rxIQRxkSe9I6Y7+3sBzcrRA4u99/712FXz36hedbFMI
vF9pMdhE5bLK17MUooD9IaE7sxzQibas4o1aDD0IWhbEDHwZr3tUPnKkXqV6Bd6vtBgaANwZgD8E
dKuBi8A46/UiHi8C35nS9cA7+vnnfAcUljaxH1DVawOv10z5vQKvLAY3gyGqcKPQ/QHv/ykArwfg
md1QpXi3BHiPQPtvAl0Exh1QwDP49kFbQybbov1AUBZ4bwdktqB5dDLNSxljQPuT/NuzHypSyxC1
iBbBYaH7b2cZ7O/7B4xWRud4O7Af2sB2aBNr4cjrXWU5KATey9VuxGIwy2UvHAHXg64H3MztFap3
drxmtRqq1e4/ELj/jNvKyIt9Stte9XZQRTOWg1SvwPsqtetZDIgy8ibRGOj+TECK3sdOus0m2zIT
bMhS4S2hdtsAwv8GwJ3ZJZ56b3+shiP1yxyXI1XsWQ5SvQLv69TuzGIwy+0cgVoLM6iiv6vgyyhe
Jp2sKpNhBNoj6B79Rkc7+++wTQBsA/XL+r0VCvbroSzw3lftZi0GMyw/l4Eu8jsK4ajlgGQ2RBXv
NrntTaa1g9sebKMWQx94vX8hPPN8u2PHeJaDVK/A+xq1aws8XdRi8KCbuW8E4Wx6mRF2Q1TxjqyG
GXj/ql0Pup7a9b7DyO+dHZdOWDJsNTOpXoH3FWoXKRcZAbCnekcwnd1mQHz0nuxqtgqPd18DAclm
8MC7BzAK3X/JdtZB28G7MKFZDlK9Au8j1S4yoebB1YJebjNsMu0IrjPgercR62Gk0qOLKJjlwkfw
jYJ3lMlQBd399/ocKN59vYiZnzuCcGWO79dCWeC9j9r17mukykX8UMbjHUEXBTJqP2RWsh0dK2bl
Gjux9t/f/xzwIsBloTtbefcJWA8Ry0GqV+B9JZCRRRKIEo4Wt/kBoes9xrMgUPBWWg3R4jhHqvez
g++/A8huDnCzSrcPlO+sTWyTY9Un7VSqV+B9jM3Aqt3ohBry+A8I3BGE0R9UBTOpZZWFckYe76wM
5MhuaIDNMDo3/wKw7YBt8pn4vtvEmumg9yvVK/C+0n5o4PNHwB1lLyDZDFHYoiD2/N4oeL0NL9G9
1tjJtQbYDBWTZoi/u/99lFrWAMvhLNUr8Coep3ZRmwEpkOPBD4Hrr8Um31jVuxq86Ko1D7yjYjhs
VbKZFWKD7zECMGI5nKl6vw7WAu99wV2ZOoZaDB5gPyRwo37vCsWLWA0oePdFzmcr0yqUbv+fY8OA
+XPwm7EcvAUVUr0C7+PhGlG7ZvGcXbbergff36TyReBb7fF62/783Uxytr3PP8JmiCrdH1D59sFF
5HMA3CPLYQ/PkfKV6hV4H2czZF5r5Oua4avUmMUTRx7sCuXr5fOiWQ1GqF4vq2EP3aPdg0eFcGY5
ulHPNgLh/XbwH5tnOnh2QwdU79faBwLvs0BdvVDC26eMzWhgPN3fItWLerwf4/ddY/db22+jPtpL
bVSH4chu8JTu6DP+7D7nDwDgz+A3M2KILKwwQVjgvbsqNrBDImr3A0K3GVfUHM3lZZTvna2GI+ge
bdU+WgocTRNDLg4/O7j+FMK3T2wHM6yATtRW+BpAC7zX2AwN+LvC262AbgS0nvKtympYrXg/hNUw
K36DerozyI5+r4AvqnpNfq3A+0Q4Z3N2zWqLnkfLQKIAjireqMc7Oq5sLu8eug1UvDPVywLYDiD7
M/GlM/CdqV4kt9cEY4H3rrbCCL4z9cuo3ZFCrKi9G/35tfXpZKzVMILuX+D2AXRntRgQmwG5ANhA
1f5M7kPg+/d79Mlx9DIczLEXZDcIvJcC1ggotAmEEbUbTR3ztvlBSkGiyneW1ZBZQIEeY8Rq2MP3
CLqjfdNGthHyWRDgZuC7B+4RfPvuM7MlIaVwBd7bw7kqfQzJbmgW332iQvVGMxqiiycqCuU0Gy86
aIbX2WU/ww9x+2PjVLh2oHCRDAdvos0AG0J2g8D7CJvBm1BjfN0RaCPwZeruotkN1UVyzPh9ymZW
Q5/AJwP9mcXwA8L3s4Puj40nBY++TxsAGYGvB1MtmhB4b28zNPJ5zbANL0e5vEyhnFlOb5Xyjapd
rxbvLHOELYaOgNcC4EXtgj1U99AdTZqNlC+jeplJtiq74fVAFnjvAWdvi3YD/UOvPgM7wYasYkO3
/8ko3jO2d99D13bQPSp+42VQjJT2D2kzjLzbIwjvf28HsO3G1/noB1D11LzsBoH39kD21Czj734m
apfZZfjH/AI6SPFzpC5vdSrZDIio4h1BdwN9+KP3+gnaDH1wLvpA+W67+2Z+L1vrOQJY2Q0C7yUK
N2IzZCbVjtSul+mAgBZRvtFtgKom1ioVb7fjwuYVxW4Qm2EE2hGMt4PbI9Xbwe/CWg7KbhB4b+Pv
RmwGS0AXyWRoIHyZIjqe7cCuWvP2W2NsmSNQzHYabhP4ZsJbiTaCbAfPzcj73QybWLMDm8EIy6HC
bng1sAXea9UvazNkUseY/F5kQuxj86yHj6OMq5YKe/BlrIaR3bCHr3fO9u/VBxaCtwz4E/wZqd4j
24GxG9iau1K7Au8tAJyxGbwJuOYAKlOPFwHzx7EcPo7inZWDZFLJKj3eI/ia4/N6ys+D78dRvFsS
vkeZDdFJthlYPaX81YAWeK+1H1CbYZZOhk6uVUEXmYwbKVmk+llkW3cmnWxUbWs/oTYqLO4NpUcV
xo5KOR5B98dRvD8EgP+q2iP4sgXaZ16vGbeYQopXcTmsPZuhwt/1oMxmO/xM1OyHuD9aChKpTOYV
JjpSb0fw/TtMZ6HSSbshYzF46jcCX9TrHSnXjMp9rRIWeNcp2+yOFCPvkgUxU6GMKabDTMj9WGwC
j8nfndkyowmgNrEZmh2v/rI/9yGqt09AfJR3iyrbn8H/RhfSUYEfLw96BmD5uALva/xdb7jMdBCm
Qlk22+GHsBDYz9AMr0rGHGez41n6v8A82gIoYzfMitwcWQ0zwHoXzaO8XVbtmmM52MReYHxegVdx
qb9rIFQzdkN2ldsqZTuCbrYOL7JZ4x64bQdc29kNI9W731zyqE7uZ6B6P6DVED1XGwlhAy0H5Dgj
/UGbXSou9Xe9BQBIwZyI2kUAWPHTwCFyxVLhRnbydmARfA4AfATfPXSPALsVgBdRvrN2wQJ3pniV
Vibw3l79sgVxzGq2/4lsAX/GTyP93exSYXYYvE8b+xxYDnsVaY5v/Jn4vEfgRS+GDbygoVbDrA1W
2QeqTqZYYilEYMAAPAJVpFQkcjs6BPbUeDMufzfi7zLKd692zf5vqtbIbvC249lnG4x8WfYcIRNs
DHzRJcTo8mEGuK+Es8B7H3jP/Emmg7C1G1oRfCMgmCm2qqXC0fOz7+yfHXxnCvIDwHezeVYDe2FE
0gUjtgOyhHjfblWnQeC9nQpm/N1GquiKHF9GVUXtCQQQmRq8bdF5/ux83iOboQ/g2wY+75HqjUCX
yQAZAdebR/CU7AjAsh4E3lvDGd2zi13JxkIXAfHHUbFIhTTPk0SyMiKWThV824HX+3HshmbzbePR
UQBycYpUc/OyQ6p9XilexWXql7EjqibXGOiiVkNzFO3HsKI+GXAw52G2ay7ixXvLtmd2wxF0PwCA
2awPduEJ6vOyClVgFngvAzHTmT074mzoosuNkWEu+phIbQZmo8sGgjYC3pHdsB/uVyreSN1iZhFF
NLsBLaYj8CrSapYtCYl4lEhuZQS6LdCZPcXL2AisIjPjykAeFcVpCRhni9NvE/iiijd7ntlVag0E
pzfBpswGgfdSgHt1XNncyigIENgis+aISmVBgHw/Vu2Oticf7a4w284c3fX5aMKNqRz2CZ6rbL4u
Uh+keoJNildxiaeL2hER3w5RT0w5Ru+5UeB6kJ1Bl7EazPjyhtFskr3dcARfL++6csGMGTaxhmxr
pAk2gfcVcEaAlIFyBIozyFZDIQoO5ng3x17wfF9vu/doHu0eyFUjBmZpsKd29/f14r7yenh/xMRb
gbkRj6ucPGEnwD4nA9bMLxDveehovqqBym/Fd2WzPD6F8DXD/V9kJ+yVo0OBVzFtWBHPsQXgnPV4
GwHaj8WzFbI2wwiKZphqY8GBKD80HWsVrCtXNkYn2ARYgffW1gIKZnaS46zOvQqqaPYCqngZQLTE
+ag49lUXrNXnBRl5oH2ifTuo5fGu82kR/zb6XMQPZpTP1R7tbLIHOQaRDj3bReEouwEZZfSA0lx1
0fRGDgaMGrxjO9uNAqnN+7WTcVK8z/B4Pc8xshvxHZSY57V6ind27FC/HHm9Bn4Oxj++wlZA1Dv6
mLPthVepYoH3fo0B6eQRWLGdlYWFEZ2beQ1kyFptNaDefNY+MeI8VHi36PljVXF7KyAF3u9VuwYM
G9mOtVp9Gfne3rFgJyDRCxn7epFJOa9mcANvMwWSjLiQee+HHNdqQfJ6eAu8z1DFTIdhbQZLKmNv
EswAZdVIgKFWQ2QnEERFRzIr2NEDO9LIPBa90LSCti5FLPDeDsrIMI4p3deCAGCGtxZ4HmptrOzc
GRXNWD2IhVE1YomqXvQCbsnzohB4b6V60VVCEe8NVaooDCNq1RIdGxneRz3GLGzRc2CgpcCMKNCM
EPQ8oxd576K1uhi9wKugFFRF7iKTx4oMyxnVyqhgdrjtfb+VHbkRQGFKfDL+NnMs2HPofQ+zWOZC
JC1SMBZ4L1G3ka3co0qyFXZYdNY9+j0jw352kqwtOKfMxCDqb0dKYbIXUOQcrRAK1UJE4FUsU83M
UmEjFScKXc8iyOayMkPUTL5uNEUtChfPbsj49Eh9CfY8MqMnC7ZHqWCB95ZDpAZ2DnZWn4VuxGpA
bIRIp49OqFVMBEUUOnNhYVfrMVZCCx7fdqP+IPAqyhtfROVFrAxkVp1RU8z3iJRubIljEIUH+pqo
pRAZmXhQPGvkgl7I2YvjkwSQwKuAgJdVvAZ2cnbJKaO4K9TomZ0++lnRXGVUxTLnEIV7xGqInsev
U9UC77NUMTrMRFdXMYqXhTqjVLPlBtui18tU3WKH8dn6FIziXQ1NhcD7GCijnTQ6m59VvOjjM2qx
3eycVNkWkYtkpeK1QFuLLKKQmhV4Hw9gVimiKWdRxRupnMZA3YDj8IROHUmFi9TriFwU2bbG1tD9
+jq7Au/3ABtJjq/qrEhpxyo1eBZgV75ONNMCLUO54iKKqGMW0gqB9zYKKArYyBYsVcNT5gIQHaKy
GQ3tonMamWSK2kvRUQ9zkY7aRs0EWoH35TBGawS04o5asVa/veQcRS4CVXWF2aI76Lk8s9aCIC3w
vkYVM521SiVFJ2iinb1ddC4iy5SzechZvz6qnkfniSmYLtAKvLcBZ0t2wOgMc0t21shy2CzI3n7h
rDqOFRfRSJt6ggAReL8UwOwkRXZo3hZ11Ohnaxd16CfaGZkLcfZcrlh5lk2dE3gVl3XEyFAVLZbC
gjiy/Jgdzr5V/UZTtRBYVY1Yosq7Mg/76wAs8L6nI0c6V8RSYJTwm2yFVnhMK+tIVOXgZoWCvFyB
9/JOeFfr44pc2RUqqL24TVXXIs68hqwBgfd1kM0OsSuG6BUKacVMd3shCKrPVwbSnmLNFspZ2fde
AX+B9/suGJFh61XKTpG/aFaC/8yLjBSv4hUqqbIATNVeW3fK373qfEVr8l45WlMIvAqr3UEg8r8m
ENzCfoiuXpNaFXhfP9Rf+X7R2eXKrXU0y50/5hFv82zfVaUhBV4ppZs0/Ozkl2Bdc5yajrXAq3iu
ylLc64IZ3SdOIfAqvkRRKxQKgVehUCxQ1AqBV6FQLACwQuBVKB4bffdbIfAqBAUB4kHn6A3vI/Aq
1HFv8Pr9S89FDz63P7y9CLyKrwNpL3i8d1//0vPRicf3xLFGjns/qT0pBN6vBW4veL1e8Hl6YSeW
Cl5zDARZgVdR0Ik6Ab2egEFPgLVfNGR+Kqj65JhFYC3YCryyAW4y/O0nfP4M6I34+8k2RD/pmArA
Aq/iYjuiB57DAiXy+A6+552Pdy8+b5FzGrErlNEi8AqewLCxJzvzrJP2xd/r7Re8HrALEJugJ4Db
F7ZJhcCrsJgfyKheBhj9ZccsAjMPyoif3hPwHU2Cdjs/G+WrwS3w3lPNZiCBTGAxMOlkR2U78F3B
3E8GBmrBRK0Gr53MLpzdpHAF3ht0wH7R+3eLebdoJ+1Fw9OrOmq/6Jwg8OxEe/IuqBm1G80HZo6z
7AiB95UXAHSInxmWZpTxkzpiZhKsJ84XYzXYAqvBSw1kFbFC4H1cJ0Y7QxXMsp0VATtykWCUdX/g
uWe8UyTdrIPw7c4oBr1QV9gaTxjBCLwvBTAC1Apvb9VkTBSQqMf4RKhGzxViQ/TA+ezg+ayyjbRA
Q+B99HAVBWtGbUU7KdMBPS96VV2JfqPzyPzdiVHDCsXbgc8RUbdKRRR4bz0EjdoCrFqsHpZGoYF+
736DjocOzzNWTUTxVlxMs3ZH9Hh2Oz9TROBVLLMvLDg8ZS2HTnyuyN9sTYi+4JhmlS0DZUZpIvBF
bjOKN3qhZ47Z1y5lFnjvCdhMB0eHp1UdNqLMVw5H+0nnhyn4ExkRdOBcdfIcMoqXtUu+uRyowPtA
BYvk1XpK11OKqEJCH+PBPWq39Bucj8hnYu0Y9v+s1YA8DlG8WStCABZ4b9WZIx0fUUARxes9FlFa
ERvjjR00mtHgKU1P1aL/R0clq9Xs1wNZ4H1XZ88qXmbyxlO8yGorViVVgpsd9qMqMJrRkP2bsRp6
4ajlyTWWBd6Xq9no0A21ICKKNwJfBu4enKLZGVcpKsYa6c4IBR1l9OD9KLSzdojX/qSKBd5HNAJk
aIh0ZLbTVqulip0qkNnvVXm3jFpFrQQPhAxEzXDYorCsSAmU2hV4bwvwqowFpsNE1W7UH2Q/J7to
o8JKqL4oe9CLqtao8kVAboH3qDo/Aq/iFHWc2Q+rakY8azV041c9rVDEjBWQWWyCnhfkvCKjBWQE
w/6w568TbdBr20xu+VfZDwLvOuBmQcsOh5HXq7AaUFVtC4ay0c6XXYjBbGPPLkqIKNyqx0cv3hn7
5vVQFXifA2smlzeraLJWAwtxs9jMf+TYXXEhZVPFWHBWKV/09VFVG1WxsiYE3kuth4hNwU7eRJVW
L4AGY1tUjBKynRqFfaSORTTTgP3ZiqDM2CGCqcB7a6hmlVsnocCAGVG9yHuY4ZNM6HB+5dYzjIUQ
eVx3FPIqAEcvqt6I5qwRyOtBLvDeE+boDPSqnM7McBUFa8U+bVddXJnVXmeAdrM6AM/OFZIhMbvo
SykLvJddhSOrfdgdKdgcUDM+EyHyHma5XNKM+mSeU5m/e4WSrZpIy0ys9YJz9FpIC7xrIV3d+Mzw
WWdUKVfOms9UDuIlIh1tZRWsbJ3jFRNl20TZbpPHb1aT/cCAmgX01ypfgfc8VZxJSYok6Ztx+bxs
x/QUnhk3Ox5RoqtHL6h3i15k2NVqjLUQtRsqRiFasSbwPh7I2cyGVWo2OknHJOojk4OVdgNqMyCW
j7fMO3KsNwCsm9X6v0aq9btaegLvF0M1a03MHrdyaWpGBSOqOLILLnvMqu73rBGmlCMDSA+0EZth
C4IYXdE2g7bUscD7CJizaT3oDhOrUo4YVezZLNUXtCqLAYWNBY/7RkKYUcOb1U/EIRehiAX3ehUs
8F6jbitW/aCg88BZZVVE0s08NVQxGdMJICALODJgzaaGbYTqzYIVPQ8rl3pL8SpK7AM2YR+t6xpR
tyugwdgIiDVhxJA307kR3zZad2J1ji6ikjeLT5bK3xV4X6uK0TzIbDHrVZBlVTiSVsZW/qqELXNB
jSy/zQC4Su2e5e+etcmpwKso8w0Zu+FM2EYKe3sq+KqcUGZyD70YVNk6Z6ndlf6uVLDAeyuVy3iT
kWImV4CY2f7dU7yrjy16/M3wXORVFgOjdiugi1gKFf6ulgwrTgd1doJtZUbCmWlnZvhCCwOPWaXX
i6aRRY7DloDuZrn83orVarINBN5bqtvsBFumIPWqHQ1W7HqA2i6VJTYjNgO60itjJzCKFYVw1j5i
LlLZCUmBV3Gq9RBZwfaUHw+sVWv/o6vOjHjeip0jNpsvbmABu1kuAyVTWL/SZugCr+IMIKOrgLJ7
sJ1ZcjDSaZmMBgTObA6v97muTCNjrQdktRpjlzC2mELgvaXKtQBQ0EmPq2HLXCDMcJ+3evacLXx+
Rq70zONFlfBm8Yk274LlFQfSfmsC722BjA7HLQDYFZZE1kecdd5Mjm5mvzb09aqqkVXl7maXEKML
KqIVzUzqWOC9o4ebnWBbUWv3blvOsB02c04ihVxWVCPLTKhtC84NctxW5Vp3gVdxBZyj3iUL4+qO
W1ULwPNco1vKRBdHVFYjy6SRrczdRVW61KvA+zogs6uGVtoNK2GMgBkBsFksbQ89N5HVdqvSyFbm
7kYm1tARWVb9CryKJSo3swfbmdWxIkW0PXhV+LyIHcDWhECXxmaP9WZrau9WZ5oYcFGUzSDw3gqy
6B5sjHXAepBn+rZGKCi0dOSZiglV1NVb+lSp3e3gh/HYvZGWrAaB97Fwju7nZQEv7goVbBZTW6zS
j6jjHjhPaKF5dCie8d69NLKMwq3cbaJC/Qq8itPUcuVCisiqpJV+oQc1D2SVHTbqCXsXgNW1d88q
Wh+1I6SKBd5bWxBZKCDpTNVwqEwbM0DxViwdZv7HlD5cNeqIbuO+2XlpZJE+IH9X4L0VqKsWUqwG
69nLhzNLh6vrH2f9XSs63qv2ToumkUVXEmqzS8Up6nZFpTK0ktkdFksYoNANAHC0DmzGg+zE51h1
AVxlQ5jl0si8cya7QeB9jPVQvZDiDgBGJ8aiWwFlFRlTHQ61G+503JlqZJn2KIUr8D4ayMwM8tl1
G6qLpM++58pjj24u6gF95caiq+wdI4//GbsJa7NLxekq1wMEmtDeT4QCW7sV3bIIBeXqzn7mrs+r
lnmjqtzI85O5OGqzSzHyNqBGG7LXOZDXjUIhAxEzbKufzAQcu0MHCgd0NwvGK71jcSLvM3sXJPYY
C7yKW6njDt7OKs4V+7N58K8qvFJpRaAlDaMbj16V1udd1OXvCrxfBdlVq6siajKbVB9ZFszYKhkw
s7ZCT7xHdAnuys0oI7V1q0YJgrPAe0tVyzS2qNfYFwKAAbpZfIKNPZboZGQGONFVXiszHzyl7dkC
Bo5gFALv1wEb3Z+MWdVmxnt+V06wVQ1/qy52CLQrLnDR6nCR9hC9oCkE3terZXQoGPEYjbwP6byR
CTZEufUFx5eZWIvkK5v5S6crLniMDYVe3HuwjX6lpSDwvs+WyGYFoDPeFRBgFXe0k0bqOkTAwr5P
VMkyFzumxgTTnipFgELgvTV0mZVqrDpDXpfp7Ga41WAWX2Za3aEjSo8FGJPjG7nQsdaNdocQeF8R
baHSjRR8QaHObNUS8XKNgA3zvhFVesbIJLo1fSR3uSdeX0AVeBWFNgRjJaBgtIDaZcBuFk9ryh6j
7Ogj6qmzxxodYSCvHx1BnJFKVi1ibh+/YtmShtKKGlUvbJBo2hCy9Q4LAkbxRgHbCBiwQ2xkMiqr
eKMXOgOOPXpRUqaCFO/XWAwr1XFmV4UMCKKK1wPLimEyO0HGjj7OOtae2mUnL/sX9x2B92XQbYsb
JFs3FvVaVyheVGkyCiwK56j/2cnvUDm6MMstwKmGbMUo72ugLPDWN4xW2FhXNUS2yEkEFpkJn6wv
GYFzBPDo94koyqr/9xv2ESleHYLlDaoFlG+7qAGjOa7sFuyoIs+Ap1rFMRttsrZI5UUuMjK4uu+s
HvkJvF8G3Ts3GlYBRT3WyIQVMuxHswvO8i2Z44huJllld5wtNNqF7y3wCrrT+1qBfXFGg4xOIEUn
1xDlG63T4PnKK7xmZjECO2K4UpFWe7ZfbUkIvP6Qf5U6aA9qhCtzOdEFEpXqNKr6mf/3E9tpBTBH
FlcLvH8FuF8NZoE3B1xUybai18koY0Y1s15tZdZBZtlwRd1eVtWyNsIKMHvAbEUjqJboJ5G2/Vr4
Crxrrsre/W3SIdpAfTTyPduC75UBSnbZb9VkGlvpLFO0/k65sJFJ20YIkyoB8hXwFXjjV+8IAKs7
kde4WXifaVt4YB1ZERlgVijSfqM2Gxn5RC7kVXZDtP2/Dr7fDt7ocCmjOhs5bGuAQo585lUKhVWj
1R5vJCsC+fxnFJpp4G8D2kObjKKiIy2v/UXb1NfB95vBW6E0o0P9rNXgDQGR1zGgQ6IWydlqmc0c
iFoY/aT2l110Ex3ZRP6PTMKxttjXwfcbwVutchnoMiD1FC8C1sgQtLojVG2/zvwdLYTeb9I+oyMk
RvEiF3Ij2mhEZETb3OMB/G3gXaFyWegy6rNS8bbA60TVbrVFES2yUzFZtxLU6BC8Tc5NVPE2p82w
7SpiKWTT3wReqVwKuqyaYBUvCmamw1UNlVlVWlG0JrvzwtmWA3Jfc+6LXISRx0a+R1Vq5Wvg+w3g
rVa5oys3C11PvSCgnEHYu8+AzlulYqoAi+zZNlK7WcuC2Q1jJXwjijRiNVjAtkCthaoVnY+F79vB
u0LlMhXFGL/OmzSp6HTmwJy9mFQcb0SNonuIMXZCdsfinmiTzOQUc6FGLtLohRq1ppCRWbTdvBa+
bwVvNC83Yy2w0EWGi0YMDZmhI+vbMZOFlcHm6LJbmmeKmUeUO3Jx8+yoyDk14rxXtBdP/TIjpVfC
943gPUvlMgsVojZDppNEcjzRC0AGupnlwpkNHkdwRnZwQLc+70VtLDKZFm0/FmwvBrQXZLT3eNtA
4L2HymWg2xb+sMrX6+gIgDND81lWAVvFLKJ4M4/x2k8LtMeqjAO0faCqGLXIKuH7OtX7++XQzQAX
GXaj0EW8wAr1G1XMTPZEBMIz77XtoNcGr9F2z2EK/aDbH0WtiNm5ZVIDDbCaVly8++43cly9c9Am
j22D89vBY3v7zTrfAt6zoVs1oZbpSFbU2VDf0ABYsPBFbIK+6/h9AFvkfHrAR7ZSz2Y2NALEq8Hq
/XSbp5R151gfXQxHwP0q+H4E3TLoMjZDZqIE/fk4f2c9YzMuNQlVwV6thZkfO7s9Amh3njsDa1/U
VldcYKuVsKfcI3m9qwqyS/FeCN0zrAXUXqjoSB8Aph/wcQxgM3vCRYrS9AOl1AGV0wzfXgjd76wD
ypgB8xkjmlnb6AG7Ae13nuJdrXxvrXp/Bd1yP/cM6M4UblbZWgFgW8Lf9SqDVXUoBKxekXMkayLa
fivbxZYA8d5u6I590yZ+bxS+UbDeFr6/gu4SP5eB7orOduYEHAJntMziEQQbCbQq8CI2BXIxMbCN
rfz5TEYRiPIdtfHu+L6ex5sF6GPh+yvo0pD1oMtmMlSA72Pn+XrR8+IVOu+AzcB0Im8GPmIlRFSx
B+JoCmRU2WYuyDZRvozdgFgIEUDL4705oDPQjS6UOGOGGrEbPkGLAfWCWeXLwi0KWtTjRX7QCwcL
3czE1koQe6O7kdI1x0r4Wr/390UwzbzGU6D7IUB6htfbQLB6oDKbr0zb/oDkAyorZCjcA/D1vgN7
PFChgNpCDHD3NsPIdhjB0gDft8nvfTZ4q3f+fQJ0jxTsx/BshaPnRVc1ze4zAG4jADO37QC+s9lz
7/09+G6ECmYn4hjlu9oL9nxes3E+L2rpIBkOiJf7CsvhV9B9vL3g2QxsOplZzSq1WV0FT11u9r8n
hPavtx185m74Kit0kQQKYMSrroAw26Zm6ncE26P7R+CN2DsoZGeAfbzl8DaP947QtROhW71YIlsY
B5lQ2wPOdtDdDgBypHhR9cTCdwMgvIF+MAPdqroMnz+fcZ/hEG0L6Eq20WMaCOLXKd0ngbc9HLqr
4Hqmr8sAF1kMgajMmbe7V3Ej+HpwYD/L39sbCN3sUuOqdjUC7n/3/1W6e9WLgLcDtlPE72XU62NU
7+9LoJt5zSdANwLbTwK4BqgbxFrwlO5m/ztP90j5boZNGO3hO+tkswm2vvOT+wC4I+jaxH6I2g0G
AvCM/G4b+OjMCIgpaPTKFLO3F8mJ7BO1Cro28e4iy4QjS4GjKUPR0o+IAvwPqNuf530cm6E78EU6
pQfJkdL1VO5WqHzPBiwCXnYlmwGK14KqN6poLwf27wNhWmUxNALGbMUxpqBIJCf3Y7Ec3ojS9Y4p
Yi0cQbbZeDJtm4DGDgDA2iJsStkIvkc/kYwIxnowQgBUZTp0sJ1EMhwQEL5O9b55AQUDcnRrba/B
m/GTalcoGBS+DLyiE2k2ULwji8HIi0bE453Bd7NYqhlyjBjQroRuZIINUb5t4PuyCysqVK/Ae4La
ZX1ddtfVCHDvWObPO/bIAgFE7dpA8W4TqDRA6UazLjJ2g6d8IzBmaj2c1ea87JE2ULWj7+VNtM0g
WqF6L4XzGxVvha+LqgzPZqhQE2fVW0AhzMB2Npm2AeemA9aJ2bxYN7rAA125NgLv0eOi6pht50ye
dUWGC1O/oZOql73vkar394HgXPG46CZ9zA4NkcZ/ZuUxJrxJq5mnuwHn4S9wZ/CNQDfq87LKl8kD
nh1Dpm2vrkDHql7P6rla9Qq8F6td1LrwhnhoJ7CJR1mdnZDZHQIFLGMxoD7mHr5sycKo1WDE9zpS
tkcAZifemHPBVI9DR1gWhO8ZqrfSTrgM1m+vxxstZxixGMzqVSgDZCNUIaN0GfgeKdt24N0eKd+/
SfxZtRutUjbzebN+b8T7ZeyHyE7S0fY22outUvUisFQe78lAXal2V1oMrG9WXb4vuocVszJrBt29
mh1BN6N2mTYUrVRWAV9EAa/sX9kcYaTC2OzinclweKSv+3TFu1rtrrQYWJsBhfoZ8EUUbnOg+18g
0P27fJXNO24J6DKWw5a0HWbwRd6f6TMsdJG2FvV5zXJ1GR5ft+FbtnePql30NSKTauaoXcbKQF8r
C1ozPFVsA1/bg65XM4Ddpfao0zP5vCvhG0k768ZXO8u0tb77v6d4M9uxI6o3C+lLIP77IHhWv9YK
tespXk/tmuWS4me+Jwqi2X5os4wFFrqfCXxZ6LZgO+oF8J0Vz/Hshy2hgBkvnrXTkBWWM0vAQACj
k2yvi2/YgeIuatcsP9ER/a5sWhUDWxS6fQLao6WpfyuSRSfVMop3hd/7/+/7V+T9skuOkYydmZDo
k/uRlWZn2Q2PAPcbrIa28DmVatfLhsgM/9hVW91RfKjSO8pYmFkKqMXQdmBnoRvJ1kB9XjM/Rxed
cKuyHyLbyjfCDhv5u2brl/m+UgF/48o1dujl+WXs+7XAZ2BXJqGwGdkLGR+3H6jbI+DuO+t/Cjdb
sjJrNUS93krbYTOsNsQIur2oz4zsBU/xsl4vs2Diinzf14K3nfC6reizNRConoKIlGNE3ofxMmfA
nYEWXRAxAu5nYi9E/OvMeWazG8ywcpAIdP+7j7EfkMLyyO+sYPFU7chq8O5bxQHtMnwzOEegjSqE
VtTIo+9jjn+L2glebi7i5x6p3ZnSjRZorwBvRvV6ZSTZyTa2HjAC5ZnVkrUEPCgj+b2aXPtCfzda
8SyroDPDZzQFiV384JVvnNkL/cBSGNkKEaVboXg7MTJAjt9WbD104zIfUO+3F/a/vrh/9xt+rq8H
b4WSZAt/o8N75LFZFf8XZiiUEVgfLendQ/fIx/Wshb/A3e8qge77VrUoBIVuVP162Q5Inm+29oNZ
fuv5iNp97SaVAu+9wJ4tyRiFbgOVL/KY0dLeUef+ENbCHrh7awHJXsgoXc9LZDIcGMsBBW9k0i1S
/8G7GLMjhKsVrmo13BySK2tAXPH59onqnvLtQVjsX+MzeN2RtfAXsugkGrqpY5UFxCrfqO2wEsAs
hNFRkULgvY1l0S74PKOye0fbrbBARd8ftRNG6UV72H4mowUPvBG1i6g21G5AwOvZDp71kIEwu+8b
A+KMVaG4EXjblx57pIbpqNbp0aTV0d+jLdMZGHu5uChwR/aCWXzH4wrPnNn+/Uj1MiMKBMAVEM6U
oIxYMXcAsAqhK8qAPAPxCLajYe9nAtuPjbMVPrvbDHAzmQtoTWQEuGi+aNTrjdg5iPJFbYhe9DNT
u4wNIQUs8D5S9c5WAx3BdwaBv9CdwXb/9wi6XnGbUebCCk83U+IyqnhZ1WuG+b4ZACOZEFHodtKC
EYQF3lMVKdLIGgBfs+O0nIj62kP3c2A7bIS6HRW3manbj/E7ZJxhMTAAjlYxM1DxsulnCIiPJt62
BHQ7CN0u6Aq8Z8IYAcDocUfr2lsAxH9v77dMH90+2r5lpm6PlPdsBdpmuZ1roxZDC4DWQJBUTLih
y41nuxajnjA74XYVdHtBHxR4X2wBoAoYaSRtAOCj27P79rD9DBTvBirLNlC3n6CPG1kGzGYxtMJO
i0wo9YDvaw78mIpnCJDZzTafpHQfq6gF3nW2wwyw3mNGq36Qoe8RfBnAjjacPFK5bK2FFRNpbXFn
ZtQvU1ISTf/aSCsCsSSYpceMxTW7iEUyKF5rVfw+AF53VL4z9WuOmp0ptVlpvHYA1yPQjuA7A+4I
vs3mu0KwGQuVE2nRmhtop44uKa6wHiI+8Ez9Is9lLxIz9T+7j/m/PN4X2QWjxzEXgD6xCCpUVjM/
R/IIBH9zZvewPbqvOeoWgW4WtlXArah7wQB4tfplsiBQFcz89tTuTPFmlyKrOtkX2gSejxtRvkyM
EsH/Ktoj1dsGf3uwHcF3byt0wk6IerkMcNvitoEOgZGKZmackvSG/lvAE2Zgy/rAFeq1Lz5/Au/F
tgWqbJGJNFb5snYDAt8GAPgIhB589+lg3bANOBEP9yp7IdJRM54vohKjix02i2VGoP9nJt6Yqnfs
hp3Z3TUE3of6w4gy7qCn24KdfwbfPWA/A891D9oRfPf1FLpxE2bVwF1lLWRVb9b3rfCAUbW6Fd1m
Vrqhx7hi4UVP/l/gLYYsWw9h9NuMK1SDQDgTR2D9TP7nwffotRoA3SxwK62FVR5v1Ho4G8CsEkb/
ZnY4rlC6X7Es+Vs9XhbiKIBHlsEZe0uhy3OP4NsAGCOQZVPEIrBtJ7eTqP3A7uRcDeAZRLfEfUxq
GKN00ZWDK4B8OsR/HwrCFa/FqN7RQgcEwD3wmap/2NdloYuq24zKbRcA1wzPNsn6v0wNiEhJyC3x
3MxFYQbgr6n78AbFiyxiqFh5htgPR3BtAfjuJ7LOgrRZbMIsCttKhdsWgTYK3giEmdVvVRXJsuUj
IyUms+ljjDVxS1j/vgyy2deKeL7eMl8WvkjOcYWSNVuThxudOFsxidaKOyK72iriA0dUcCWIK+r3
mnPRQNTuK7MZ3u7xztK+KuotsCBG4etlUDQAxN3yqjZrJyBWwhl+bjV4PS9yBXwjVsRKEJutsRcq
zsUj/N27gTejZrO+LqN2vd1UK3dYPQLuUaZBB+FrVjthVpWPu2oSrS3qaD0AgWjmwxlK2BbB1waf
M3o8XqN820M/Twve54EBmYGf/fbuQ2+f/fdVHu5Z9sIKtRPJeLgCwJYEaFRVo59rpPIR8CLFeaR4
H+D/ZnzfSuWLWCUNULo9AFTGx42oXQbAdxEJkQ58JYAtCE4W0uhrzywG7wK2svTkZer5jlXBrla9
ZyrgrOrMeLORibKzshXaCW2oL+y0Eeh6sGE94KwqRu5jHzP7/Oj3f7zafSN4kU5+tv2AQI2BsXdf
5j0zkM3A9o2KF4VCxQq4lRBGQYo+1oNvRO0KvCd/pgrVeyZ8qxRyBtirYFsxYdZObj9nDFezNR/O
gjCrVDPqtge/9yuge1fwnqF6IyoYXfq6CsBZIFdZB5lKYu3BbbJSCVekn0XAm4Fx9vWi0BV4H6R6
r4BvFnDVYF4JXO9/Am8Ovlk1GAFwFJyZ+66C7uXg/b1xg24nvw+SSYAsmEAyGKrye48+w1kKl1G6
FaOYN8KXUb8rARwFaeZ5LHSvOGdfp3jPVL1RZVehiKvU8V1thLfC9i4KOGNBZKyK7GMj0H2N2n1C
g78Kvlk4VQKYAeaZsI0o2/bwdtoXPC/jAbPgrYZy5Pcq6KLn5xYr356gNCpUUzV8qwFcCc1Vvi0L
2vaydniG7RCBDgqxs8GM/o+F7uPV7tvAewZ8KyBWrUbPBG0Usu0l7XT1Zo4rLIiI6mRAXKluvwK6
T1Iad4bvKmW8CvIZK0Hq9nwVzG6VkwVxBZSzt1nAPsZieGInWAnfKHgqALxCoWYhu3KJr8B7nv0Q
BV1GuWbebxV0Bd4TP2sFfO8E5Oz7ZADbTjov3wzejOrNwLhKqVZB9vXQfWInuAq+KwB8ljLNwrWd
eE6+Hbys8o2Aq1otV7z2V0H3qZ3gDPhmgbUC0mfdtwK23wjcbOfPAjiqJrPKuFLVvhK6T+4M1Sqr
AsCr4dkWfD5ZCe+0ISrhXAnYiiyF1Vu9C7w3h+9KGF4J0yvqKbwB1v3k1+uL7q+AZ2bBw+uh+4YG
v2LIu9qaOOP+KoDKUrgXrDNQqpy4yzwvaiG8Brpv6SSr4FCtGM96DntM7pAKJsWbf92V4K2yCnrx
Z38kdN+kTlYqszMVcpUSPROmUrj3gHf1LhlX3r9iRCDw3uy7nAHgKlg+JbVLMD4fEhWrtyJD/V78
eq+H7hs7yBnbgrebPWa14lfbeoYtUT0cvwqo/SbHU53j5O/Ubv64u4JW0L0PKFaoxRVq+Suh+/YO
cqZ3ebfJLKV7CdJnDNv7Sa/zKuh+Q4dqF73G0+AqsArIZynjrwbuN3W4doPXudoaEFgF5pXPP3Mi
rL/h5HxTh7wTyN6YwiW4PxMi/cbPeSV0v7Wz3NX/bDrPiptC/WqF2t92cr65Qz4ph7WpLQmOD/o8
/aXHR53lQcdAaVyKt0O+P+izCrxffCyajpHiwfDpX/id1ZFefFx0PhTfeAHo33ZQ1dGfd4x0zhRv
AF//5gOuTvz+46VzLJjqM6lT6vjpmAiK+q7qJAodT4VCoBUodJwVCkFWQFDoPCgEV4U6vM6dDoFC
MFUoFAqFQqFQKBQKhUKhUCgUCoVCoVAoFAqFQqFQKBQKhUKhUCgUCoVCoVAoFAqFQqFQKBQKhUKh
ULw9/p8AAwBXFb0goYN0fwAAAABJRU5ErkJggg==" transform="matrix(0.24 0 0 0.24 -12.4536 -8.4766)">
				</image>
				<g>
					<path fill="#BA7967" d="M52.588,58.716c-0.332-0.08-0.941,0.076-1.184-0.166c-0.318-0.318-0.783-0.76-1.25-0.826
						c-0.623-0.088-1.244-0.176-1.865-0.264c-0.396-0.057-0.748-0.682-1.008-0.969c-0.213-0.238-0.959-0.477-1.258-0.676
						c-0.385-0.258-0.795-0.82-1.266-0.766c0.012,0.057,0.016,0.115,0.018,0.174c-1.361-0.969-2.281-2.063-3.318-3.396
						c-0.133-2.094-0.25-4.164-0.27-6.256c0.635-1.049,1.121-2.158,1.563-3.373c0.463-1.275,0.678-2.457,0.877-3.695
						c0.32-0.254,0.473-0.381,0.615-0.801c0.15-0.434,0.295-0.842,0.381-1.291c0.162-0.854,0.346-1.688,0.705-2.484
						c0.365-0.813,0.762-1.618,1.098-2.444c0.346-0.857,0.357-1.793,0.547-2.697c0.076-0.357-0.035-0.792-0.365-0.929
						c-0.465-0.194-0.744-0.022-1.18,0.261c-0.439,0.283-0.793,0.554-1.039,0.916c0-0.121,0-0.242,0-0.363
						c0-0.135,0.016-0.271,0.039-0.409c0.08-0.449,0.266-0.909,0.426-1.362c0.02-0.054,0.039-0.107,0.055-0.16
						c0.008-0.023,0.016-0.046,0.023-0.069c0.023-0.073,0.049-0.145,0.068-0.217c0.004-0.015,0.008-0.03,0.012-0.045
						c0.023-0.077,0.043-0.154,0.059-0.231c0.805-3.753,0.928-7.87-0.84-11.405c-1.957-3.916-4.799-6.044-8.854-7.564
						C33.506,6.505,31.959,6.324,30,6.756c-1.909,0.421-3.659,1.309-5.341,2.27c-3.371,1.926-5.408,5.252-6.231,9.001
						c-0.406,1.85-0.593,3.719-0.429,5.623c0.01,0.114,0.022,0.228,0.035,0.34c0.004,0.034,0.009,0.067,0.013,0.101
						c0.009,0.082,0.021,0.163,0.031,0.244c0.005,0.034,0.011,0.069,0.016,0.103c0.013,0.09,0.027,0.18,0.042,0.269
						c0.004,0.02,0.007,0.041,0.011,0.06c0.196,1.159,0.501,2.276,0.664,3.438c-0.355-0.296-0.867-0.505-1.161-0.465
						c-1.047,0.14-0.632,1.088-0.531,1.814c0.103,0.747,0.295,1.458,0.499,2.187c0.39,1.396,0.727,2.694,1.301,3.995
						c0.001,0.363,0.005,0.727,0.012,1.09c0.048,2.408,0.988,4.977,1.784,7.221c0.292,0.82,0.729,1.643,1.234,2.445
						c0.083,1.709,0.307,3.65-0.26,5.18c-0.591,1.596-1.946,2.678-3.312,3.635c0.033-0.088,0.074-0.172,0.127-0.256
						c-0.602,0.35-1.279,0.922-1.941,1.127c-0.347,0.107-0.522-0.125-0.768,0.186c-0.28,0.352-0.421,0.746-0.883,0.746
						c-0.421,0-0.31,0.299-0.641,0.389c-0.378,0.102-0.917,0-1.309,0c-0.456,0-0.537,0.166-0.879,0.488
						c-0.299,0.283-0.718,0.295-1.116,0.387c-0.294,0.068-0.637,0.018-0.938,0.018c-0.33,0-0.581,0.518-0.884,0.621
						c-0.631,0.215-1.459-0.186-2.1,0.014c-1.528,0.475-2.9,0.646-4.499,0.803h16.864h1.701h20.183h6.002h7.988
						C54.348,59.417,53.586,58.956,52.588,58.716z"/>
				</g>
			</g>
			<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="32.376" y1="59.8271" x2="32.376" y2="44.9912">
				<stop  offset="0" style={{ stopColor : "#DDB39D" }}/>
				<stop  offset="1" style={{ stopColor : "#D69E87" }}/>
			</linearGradient>
			<path fill="url(#SVGID_5_)" d="M47.297,59.827c-0.398-0.775-0.795-1.553-1.191-2.328c-0.262-0.512-0.631-1.797-1.117-2.127
				c-1.482-1.004-2.441-2.143-3.531-3.545c-0.145-2.287-0.273-4.545-0.273-6.836l-19.261,0.582c0,1.898,0.438,4.287-0.233,6.098
				c-0.726,1.959-2.6,3.141-4.233,4.273l1.955,3.883H47.297z"/>
			<path fill="#231F20" d="M21.112,59.827c-1.031-0.902-3.575-3.277-2.608-4.777c-0.602,0.35-1.279,0.922-1.941,1.127
				c-0.347,0.107-0.522-0.125-0.768,0.186c-0.28,0.352-0.421,0.746-0.883,0.746c-0.421,0-0.31,0.299-0.641,0.389
				c-0.378,0.102-0.917,0-1.309,0c-0.456,0-0.537,0.166-0.879,0.488c-0.299,0.283-0.718,0.295-1.116,0.387
				c-0.294,0.068-0.637,0.018-0.938,0.018c-0.33,0-0.581,0.518-0.884,0.621c-0.631,0.215-1.459-0.186-2.1,0.014
				c-1.528,0.475-2.9,0.646-4.499,0.803H21.112z"/>
			<path fill="#231F20" d="M55.285,59.827c-0.938-0.41-1.699-0.871-2.697-1.111c-0.332-0.08-0.941,0.076-1.184-0.166
				c-0.318-0.318-0.783-0.76-1.25-0.826c-0.623-0.088-1.244-0.176-1.865-0.264c-0.396-0.057-0.748-0.682-1.008-0.969
				c-0.213-0.238-0.959-0.477-1.258-0.676c-0.385-0.258-0.795-0.82-1.266-0.766c0.168,0.898-0.861,2.02-1.314,2.707
				c-0.514,0.783-1.451,1.443-2.148,2.07H55.285z"/>
			<path fill="#DDB39D" d="M43.529,38.581c0.389-0.311,0.555-0.42,0.713-0.879c0.15-0.434,0.295-0.842,0.381-1.291
				c0.162-0.854,0.346-1.688,0.705-2.484c0.365-0.813,0.762-1.618,1.098-2.444c0.346-0.857,0.357-1.793,0.547-2.697
				c0.076-0.357-0.035-0.792-0.365-0.929c-0.465-0.194-0.744-0.022-1.18,0.261c-0.689,0.444-1.168,0.86-1.355,1.641
				c-0.281,1.183-0.563,2.365-0.844,3.547c-0.158,0.664-0.09,1.168-0.023,1.859C43.313,36.304,43.422,37.442,43.529,38.581z"/>
			<path fill="#DDB39D" d="M19.193,36.321c-0.748-1.498-1.128-2.977-1.576-4.581c-0.203-0.729-0.396-1.439-0.499-2.187
				c-0.101-0.726-0.516-1.674,0.531-1.814c0.468-0.063,1.487,0.501,1.549,1.05c0.108,0.962,0.217,1.924,0.326,2.886
				c0.077,0.672-0.079,1.411-0.131,2.083C19.327,34.612,19.26,35.468,19.193,36.321z"/>
			<path fill="#DDB39D" d="M30.7,53.419c0.955-0.211,1.908-0.42,2.863-0.631c0.732-0.16,1.576-0.988,2.213-1.414
				c1.76-1.174,2.811-2.455,4.166-4.063c1.314-1.557,2.115-3.207,2.809-5.113c0.66-1.816,0.816-3.447,1.139-5.314
				c0.131-0.758-0.027-1.541,0.104-2.287c0.182-1.041,0.396-2.038,0.396-3.093c0-0.944,0-1.889,0-2.834
				c0-0.81,0.508-1.681,0.682-2.493c0.805-3.753,0.928-7.87-0.84-11.405c-1.957-3.916-4.799-6.044-8.854-7.564
				C33.506,6.505,31.959,6.324,30,6.756c-1.909,0.421-3.659,1.309-5.341,2.27c-3.371,1.926-5.408,5.252-6.231,9.001
				c-0.406,1.85-0.593,3.719-0.429,5.623c0.172,1.986,0.867,3.786,0.919,5.791c0.065,2.457-0.037,4.923,0.012,7.384
				c0.048,2.408,0.988,4.977,1.784,7.221c0.772,2.174,2.567,4.357,3.943,6.191C26.217,52.317,28.113,53.419,30.7,53.419z"/>
			<path fill="#AE6358" d="M25.903,43.825c1.524,0.518,3.159,1.008,4.777,1.107c1.708,0.105,3.166-0.357,4.833-0.553
				c-0.697-0.326-1.467-0.938-2.232-1.059c-0.768-0.121-1.709,0.52-2.412,0.08C29.369,42.464,27.442,43.378,25.903,43.825z"/>
			<path fill="#5E1F16" d="M35.346,44.401c0.057-0.008,0.113-0.016,0.168-0.021c-0.697-0.326-1.467-0.938-2.232-1.059
				c-0.768-0.121-1.709,0.52-2.412,0.08c-1.501-0.938-3.428-0.023-4.966,0.424c0.011,0.004,0.023,0.01,0.035,0.014
				C29.12,43.532,32.221,43.78,35.346,44.401z"/>
			<path fill="#936A52" d="M37.443,46.536c-0.447-1.701,0.654-3.201-0.277-4.914c-0.939-1.723-3.236-1.24-4.889-1.662
				c-0.762-0.195-1.957-0.135-2.772-0.135c-0.825,0-1.627-0.002-2.442,0.141c-2.041,0.354-2.893,1.979-2.654,3.938
				c0.182,1.504-0.541,3.244-0.901,4.814c0.396,0.527,0.788,1.035,1.151,1.52c1.56,2.08,3.455,3.182,6.042,3.182
				c0.955-0.211,1.908-0.42,2.863-0.631c0.732-0.16,1.576-0.988,2.213-1.414c0.844-0.563,1.523-1.15,2.152-1.785
				C37.904,48.628,37.74,47.671,37.443,46.536z M36.059,45.474c0,0.537-0.053,0.727-0.305,1.203
				c-0.35,0.664-1.258,0.801-1.932,0.801c-0.125,0-0.322-0.346-0.402-0.441c-0.049-0.059,0.287-0.313,0.348-0.375
				c0.273-0.279,0.498-0.57,0.717-0.893c-1.131-0.377-2.244-0.512-3.408-0.729c-1.146-0.215-2.407,0.225-3.581,0.281
				c0.108,0.139,0.514,0.502,0.472,0.674c-0.102,0.418-0.203,0.836-0.305,1.256c-0.151,0.621-1.436-0.281-1.676-0.504
				c-0.111-0.104-0.647-0.502-0.647-0.668c0-0.324-0.089-0.779,0.007-1.09c0.186-0.605,0.33-1.385,0.921-1.691
				c0.694-0.359,1.281-0.662,2.066-0.73c0.339-0.029,0.676-0.023,1.017-0.023c0.333,0,0.609,0.178,0.958,0.16
				c0.407-0.021,0.692,0.043,1.092,0.146c0.301,0.076,0.729-0.119,1.027-0.186c0.496-0.111,1.412,0.313,1.887,0.449
				c0.467,0.135,0.689,0.18,1.02,0.525c0.221,0.23,0.615,0.541,0.725,0.828C36.158,44.729,36.059,45.181,36.059,45.474z"/>
			<path fill="#936A52" d="M33.457,27.068C34.002,27.914,33.971,28,34.965,28c0.76,0,1.52,0,2.279,0c1.48,0,2.863-0.147,4.348,0
				C39.061,26.785,36.18,26.29,33.457,27.068z"/>
			<path fill="#936A52" d="M28.854,27.185c-2.44-0.639-5.106-1.127-7.321,0.349c1.367,0,2.682,0.226,4.039,0.311
				c0.674,0.043,1.166-0.013,1.807,0.204C28.415,28.4,28.428,28.162,28.854,27.185z"/>
			<g>
				<path fill="#A7A9AC" d="M28.98,29.417c0,0,0.991-0.204,2.359-0.204c1.369,0,2.359,0.204,2.359,0.204"/>
				<path fill="#342113" d="M33.648,29.663c-0.01-0.002-0.984-0.199-2.309-0.199c-1.325,0-2.299,0.197-2.309,0.199l-0.101-0.489
					c0.042-0.009,1.031-0.209,2.41-0.209c1.379,0,2.367,0.201,2.41,0.209L33.648,29.663z"/>
			</g>
			<g>
				<path fill="#D1D3D4" d="M19.689,31.922c2.056,1.341,4.143,1.573,6.553,1.573c1.259,0,1.836-0.395,2.518-1.417
					c0.563-0.845,0.902-2.219,0.597-3.186c-0.294-0.934-2.355-0.854-3.123-0.93c-1.081-0.107-2.169-0.06-3.254-0.06
					c-0.66,0-1.321,0-1.981,0c-0.648,0-0.828,0.052-1.241,0.558C19.213,29.125,19.63,31.156,19.689,31.922z"/>
				<path fill="#342113" d="M26.243,33.646c-2.511,0-4.6-0.271-6.635-1.598l-0.062-0.04l-0.005-0.074
					c-0.012-0.15-0.038-0.352-0.067-0.58c-0.13-1.016-0.309-2.406,0.168-2.988c0.451-0.552,0.674-0.612,1.357-0.612h1.981
					c0.254,0,0.509-0.003,0.764-0.005c0.255-0.002,0.511-0.005,0.767-0.005c0.477,0,1.105,0.008,1.739,0.071
					c0.126,0.013,0.288,0.021,0.47,0.03c0.997,0.051,2.504,0.127,2.781,1.005c0.333,1.054-0.068,2.494-0.615,3.314
					C28.137,33.286,27.495,33.646,26.243,33.646z M19.833,31.836c1.955,1.253,3.979,1.511,6.41,1.511
					c1.145,0,1.704-0.316,2.393-1.351c0.508-0.763,0.884-2.093,0.579-3.058c-0.214-0.679-1.651-0.752-2.51-0.795
					c-0.188-0.01-0.354-0.018-0.484-0.031c-0.621-0.062-1.24-0.069-1.709-0.069c-0.254,0-0.509,0.002-0.764,0.005
					c-0.255,0.003-0.511,0.005-0.767,0.005h-1.981c-0.607,0-0.745,0.037-1.125,0.502c-0.395,0.482-0.212,1.908-0.103,2.759
					C19.796,31.514,19.819,31.692,19.833,31.836z"/>
			</g>
			<g>
				<path fill="#D1D3D4" d="M43.012,32.242c-2.057,1.341-4.143,1.573-6.555,1.573c-1.258,0-1.834-0.395-2.516-1.417
					c-0.564-0.846-0.902-2.219-0.598-3.186c0.295-0.933,2.355-0.854,3.123-0.93c1.08-0.106,2.17-0.06,3.254-0.06
					c0.66,0,1.32,0,1.98,0c0.65,0,0.828,0.052,1.242,0.558C43.488,29.446,43.07,31.478,43.012,32.242z"/>
				<path fill="#342113" d="M36.457,33.966c-1.25,0-1.893-0.359-2.641-1.483c-0.547-0.82-0.949-2.26-0.615-3.314
					c0.275-0.877,1.783-0.954,2.779-1.004c0.184-0.009,0.346-0.018,0.473-0.03c0.635-0.063,1.264-0.07,1.742-0.07
					c0.256,0,0.51,0.002,0.764,0.005c0.254,0.003,0.508,0.005,0.762,0.005h1.98c0.684,0,0.906,0.06,1.359,0.613
					c0.475,0.581,0.297,1.967,0.168,2.98c-0.029,0.232-0.055,0.436-0.066,0.588l-0.006,0.074l-0.063,0.04
					C41.059,33.694,38.969,33.966,36.457,33.966z M38.195,28.363c-0.471,0-1.092,0.008-1.713,0.069
					c-0.131,0.013-0.299,0.021-0.486,0.031c-0.859,0.043-2.295,0.117-2.51,0.795c-0.305,0.965,0.07,2.295,0.58,3.058
					c0.689,1.034,1.248,1.351,2.391,1.351c2.434,0,4.457-0.26,6.412-1.511c0.014-0.145,0.037-0.326,0.063-0.528
					c0.107-0.85,0.289-2.271-0.104-2.751c-0.383-0.466-0.52-0.503-1.127-0.503h-1.98c-0.254,0-0.51-0.002-0.764-0.005
					C38.703,28.365,38.449,28.363,38.195,28.363z"/>
			</g>
			<path fill="#C9987D" d="M45.07,26.177c0.805-3.753,0.928-7.87-0.84-11.405c-1.957-3.916-4.799-6.044-8.854-7.564
				C33.506,6.505,31.959,6.324,30,6.756c-1.909,0.421-3.659,1.309-5.341,2.27c-3.371,1.926-5.408,5.252-6.231,9.001
				c-0.406,1.85-0.593,3.719-0.429,5.623c0.136,1.574,0.599,3.031,0.813,4.564c0.053,0.039,0.105,0.077,0.158,0.115
				c0.631-1.763,1.194-3.117,0.769-4.996c-0.397-1.756-0.468-3.396,0.181-5.106c0.713-1.88,1.223-3.234,3.209-3.973
				c1.631-0.605,3.522-0.295,5.022,0.438c3.264,1.591,6.564-0.361,9.828-0.738c0.805-0.093,1.645-0.366,2.396-0.035
				c1.168,0.516,1.414,0.812,2.129,1.885c0.939,1.41,1.09,3.301,1.424,4.919c0.135,0.66-0.291,1.568-0.311,2.278
				c-0.023,0.825-0.064,1.728,0.109,2.536c0.176,0.817,0.328,1.634,0.477,2.457c0.029,0.171,0.111,0.251,0.225,0.268
				C44.549,27.572,44.926,26.854,45.07,26.177z"/>
			<path fill="#D69E87" d="M33.117,38.798c0.178-0.221,1.818-1.119,1.627-0.441C34.516,39.171,33.887,39.142,33.117,38.798z"/>
			<path fill="#D69E87" d="M30.35,38.972c-0.186-0.215-1.666-1.463-1.748-0.582C28.553,38.915,30.05,38.929,30.35,38.972z"/>
		</g>
	</g>
	<g>
		<defs>
			<circle id="SVGID_6_" cx="30" cy="30" r="29.826"/>
		</defs>
		<clipPath id="SVGID_7_">
			<use xlinkHref="#SVGID_6_"  overflow="visible"/>
		</clipPath>
	</g>
</g>
</svg>

// ------------------------------------------------------------

export default HeadSvg;

// ------------------------------------------------------------
