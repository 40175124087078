// ------------------------------------------------------------
// tech.js
// ------------------------------------------------------------

import AiIcon from './img/aiIcon.svg';
import AwsIcon from './img/awsIcon.svg';
import D3Icon from './img/d3Icon.svg';
import EthereumIcon from './img/ethereumIcon.svg';
import JsIcon from './img/jsIcon.svg';
import NodeIcon from './img/nodeIcon.svg';
import PsIcon from './img/psIcon.svg';
import PythonIcon from './img/pythonIcon.svg';
import React from 'react';
import ReactIcon from './img/reactIcon.svg';
import ReactNativeIcon from './img/reactNativeIcon.svg';
import TechIcon from './techIcon.js';

// ------------------------------------------------------------

const Tech = ( ) => {
    const title = '{ TECH }';

    return (
        <div className="techDiv" id="tech-container">
            <div className="scrollAnchor" id="tech-div"></div>
            <div className="titles">{title}</div>
            <div className="techRow">
                <TechIcon label="Web" IconSrc={JsIcon} />
                <TechIcon label="React" IconSrc={ReactIcon} />
                <TechIcon label="React Native" IconSrc={ReactNativeIcon} />
                <TechIcon label="D3" IconSrc={D3Icon} />
                <TechIcon label="Node" IconSrc={NodeIcon} />
            </div>
            <div className="techRow">
                <TechIcon label="AWS" IconSrc={AwsIcon} />
                <TechIcon label="Python" IconSrc={PythonIcon} />
                <TechIcon label="Ethereum" IconSrc={EthereumIcon} />
                <TechIcon label="Photoshop" IconSrc={PsIcon} />
                <TechIcon label="Illustrator" IconSrc={AiIcon} />
            </div>
        </div>
    );
}

// ------------------------------------------------------------

export default Tech;

// ------------------------------------------------------------
