// ------------------------------------------------------------
// footer.js
// ------------------------------------------------------------

import React from 'react';

// ------------------------------------------------------------

const Footer = ( ) => {
    return (
        <div className="footerDiv">
        </div>
    );
}

// ------------------------------------------------------------

export default Footer;

// ------------------------------------------------------------
