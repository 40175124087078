// ------------------------------------------------------------
// techIcon.js
// ------------------------------------------------------------

import React from 'react';

// ------------------------------------------------------------

const TechIcon = ({ label, IconSrc }) => {
    return (
        <div className="techIconCol">
            <div className="techIconDiv">
                <img className="techIcon" src={IconSrc} alt="" />
            </div>
            <div className="techIconLabel">{label}</div>
        </div>
    );
}

// ------------------------------------------------------------

export default TechIcon;

// ------------------------------------------------------------
