// ------------------------------------------------------------
// experience.js
// ------------------------------------------------------------

import AdobePng from './img/adobe.png';
import AzzulePng from './img/azzule.png';
import CandorPng from './img/candor.png';
import CsulbPng from './img/csulb.png';
import FreelancePng from './img/freelance.png';
import LatticePng from './img/lattice.png';
import LinkedInSvg from './img/linkedin.svg';
import MolecularPng from './img/molecular.png';
import React from 'react';

// ------------------------------------------------------------

const LatticeInfo = [
    "Built and deployed a variety of cloud based web services for a large biotech company",
    "Helped rapidly build a COVID-19 test tracking and reporting dashboard system",
    "Tech used: Python, Flask, AWS, CDK, Docker, Docker Compose, BLAST, React, Superset",
];

const FreelanceInfo = [
    "Guided startups on the blockchain and cryptocurrency industry and ecosystem",
    "Designed strategies for turning founder ideas into viable products",
    "Built a decentralized crypto platform with built in dapp support and distribution", // "Experimented with business and technology models in the emerging decentralized paradigm",
    "Tech used: React, Node, Express, React Native, Solidity, Ethereum",
];

const CandorInfo = [
    "Built and shipped web and mobile frontends using react and react native for a variety of apps",
    "Quickly prototyped new designs in code and using design tools for user testing and releases",
    "Tech used: React, React Native, Redux, Node, AWS, Heroku, Google Analytics",
];

const AzzuleInfo = [
    "Capitalized on my software and biology background by building and managing a small analytics and data viz team for the food safety industry",// "Built and managed a small analytics and data viz team to derive value from food safety data",
    "Worked with major retailers and distributors (e.g. Walmart, Sysco, Costco, Wendy's) to create actionable data-driven food safety and supply chain reports",
    "Evangelized the value of data-driven analysis for food safety to the industry directly and at major conferences (GFSI, PMA, etc...) ",
    "Tech used: Backbone.js, D3, Tableau, Adobe Illustrator, Python, R, MySQL",
];

const MolecularInfo = [
    "Designed and built an interactive online service for molecular biologists that automated, optimized, and visualized primer design and PCR protocols",
    "Translated academic biochemical research into web based services for the Life Sciences lab technician",
    "Tech used: ActionScript, Adobe Flex, Adobe Flash, Javascript, AWS",
];

const AdobeInfo = [
    "Built and managed global data centers for Adobe's online and internal services",
    "Automated remote operations, led data center migrations, and handled service and data center outages",
    "Part of a small team that built a private elastic cloud",
    "Tech used: Cisco IOS, Force10 FTOS, F5, Juniper, Linux, Python, Xen",
];

const CsulbInfo = [
    "Minor, Chemistry",
    "Minor, Mathematics",
];

// ------------------------------------------------------------

const bulletPoints = ( sentences ) => {
    return (
        <div>
            {sentences.map( sentence => {
                return <div key={sentence} className="bulletPoints">{sentence}</div>;
            } )}
        </div>
    );
};

// ------------------------------------------------------------

const Experience = ( ) => {

    // ------------------------------------------------------------

    const title = '{ EXPERIENCE }';

    const linkedindesc = "My Linkedin page, https://linkedin.com/in/robert-mccone-2156487";

    const openLinkedIn = ( ) => {
        //window.open( 'https://linkedin.com/pub/robert-mccone/7/648/215', '_blank' );
        window.open( 'https://linkedin.com/in/robert-mccone-2156487', '_blank' );
    };

    // ------------------------------------------------------------

    return (
        <div className="expDiv" id="experience-container">
            <div className="scrollAnchor" id="experience-div"></div>
            <div className="titles">{title}</div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={LatticePng} alt="" />
                    </div>
                    <div className="company">Lattice Automation</div>
                    <div className="duration">Jan 2021 - Jun 2021</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">Senior Software Engineer</div>
                    {bulletPoints( LatticeInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={FreelancePng} alt="" />
                    </div>
                    <div className="company">Freelance</div>
                    <div className="duration">May 2017 - Dec 2020</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">Blockchain and Tech Consultant</div>
                    {bulletPoints( FreelanceInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={CandorPng} alt="" />
                    </div>
                    <div className="company">Candor, Inc.</div>
                    <div className="duration">Mar 2016 - Mar 2017</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">Senior Software Engineer</div>
                    {bulletPoints( CandorInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={AzzulePng} alt="" />
                    </div>
                    <div className="company">Azzule Systems</div>
                    <div className="duration">Jul 2012 - Jan 2015</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">Data Scientist</div>
                    {bulletPoints( AzzuleInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={MolecularPng} alt="" />
                    </div>
                    <div className="company">Molecular Revolution</div>
                    <div className="duration">Aug 2011 - Jun 2012</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">CSO/Cofounder</div>
                    {bulletPoints( MolecularInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={AdobePng} alt="" />
                    </div>
                    <div className="company">Adobe Systems</div>
                    <div className="duration">Aug 2007 - Mar 2011</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">SaaS Operations Engineer</div>
                    {bulletPoints( AdobeInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="expRow">
                <div className="expIconCol">
                    <div className="expIconDiv">
                        <img className="expIcon" src={CsulbPng} alt="" />
                    </div>
                    <div className="company">CSULB</div>
                    <div className="duration">Graduated Jun 2006</div>
                </div>
                <div className="workColumn">
                    <div className="workTitle">BS, Cell and Molecular Biology</div>
                    {bulletPoints( CsulbInfo )}
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

            <div className="linksRow" onClick={openLinkedIn}>
                <div className="linksIconDiv">
                    <img className="linksIcon" src={LinkedInSvg} alt="" />
                </div>
                <div className="linksInfoCol">
                    <div className="linksName">Linkedin</div>
                    <div className="linksDescription">{linkedindesc}</div>
                </div>
            </div>

            {/* ------------------------------------------------------------ */}

        </div>
    );
}

// ------------------------------------------------------------

export default Experience;

// ------------------------------------------------------------
