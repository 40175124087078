// ------------------------------------------------------------
// index.js
// ------------------------------------------------------------

import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import App from './app.js';
import * as serviceWorker from './serviceWorker';

// ------------------------------------------------------------

ReactDOM.render( <App /> , document.getElementById( 'root' ) );

// ------------------------------------------------------------

serviceWorker.unregister( );

// ------------------------------------------------------------