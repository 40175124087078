// ------------------------------------------------------------
// header.js
// ------------------------------------------------------------

import HeadSvg from './img/headSvg.js';
import React from 'react';

// ------------------------------------------------------------

const Header = ({ selected, options, onSelected }) => {

    // ------------------------------------------------------------

    const BtnStyle = "headerRowButton ";
    const BtnWideStyle = "headerWideButton ";
    const SelectedStyle = "headerRowButtonSelected ";

    const AboutStyle = ( selected === options.about ) ? BtnStyle + SelectedStyle : BtnStyle;
    const TechStyle = ( selected === options.tech ) ? BtnStyle + SelectedStyle : BtnStyle;
    const ExpStyle = ( selected === options.experience ) ? BtnStyle + BtnWideStyle + SelectedStyle : BtnStyle + BtnWideStyle;
    const LinksStyle = ( selected === options.links ) ? BtnStyle + BtnWideStyle + SelectedStyle : BtnStyle + BtnWideStyle;

    // ------------------------------------------------------------

    return (
        <div className="headerDiv">
            <div className="headerContainer">
                <div className="headerRowPic">
                    <HeadSvg size={60} />
                </div>
                <div className="headerRowSpacer onlyDesktop"></div>
                <div className={AboutStyle} onClick={( ) => { onSelected( options.about ) }}>ABOUT</div>
                <div className={TechStyle} onClick={( ) => { onSelected( options.tech ) }}>TECH</div>
                <div className={ExpStyle} onClick={( ) => { onSelected( options.experience ) }}>EXPERIENCE</div>
                <div className={LinksStyle} onClick={( ) => { onSelected( options.links ) }}>PORTFOLIO</div>
            </div>
        </div>
    );

    // ------------------------------------------------------------

}

// ------------------------------------------------------------

export default Header;

// ------------------------------------------------------------
