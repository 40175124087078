// ------------------------------------------------------------
// about.js
// ------------------------------------------------------------

import CoderSvgSrc from './img/humancode.svg';
import React from 'react';

// ------------------------------------------------------------

const About = ( ) => {
    const title = '{ ROBERT McCONE }';
    const info = "Over 15 years of frontend, backend, data driven programming, and network experience from a diverse range of industries";

    return (
        <div className="aboutDiv">
            <div className="titles">{title}</div>
            <div className="aboutInfo">
                <img className="coderImg" src={CoderSvgSrc} alt=""/>
                <div className="aboutInfoText">{info}</div>
            </div>
            <div className="inoutText">Input caffeine, output code</div>
        </div>
    );
}

// ------------------------------------------------------------

export default About;

// ------------------------------------------------------------
