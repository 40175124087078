// ------------------------------------------------------------
// app.js
// ------------------------------------------------------------

import About from './about.js';
import Experience from './experience.js';
import Footer from './footer.js';
import Header from './header.js';
//import Links from './links.js';
import Portfolio from './portfolio.js';
import React, { Component } from 'react';
import Tech from './tech.js';
import Wave from './img/wave.svg';

// ------------------------------------------------------------

const HeaderButtons = {
    about : 'about',
    tech : 'tech',
    experience : 'experience',
    links : 'links',
};

// ------------------------------------------------------------

class App extends Component {
    constructor( ) {
        super( );
        this.state = {
            selected : HeaderButtons.about,
            clicked : false,
            timeout : null,
        };
    }

    // ------------------------------------------------------------

    componentDidMount( ) {
        window.addEventListener( 'scroll', ( ) => {
            if ( !this.state.clicked ) {
                const techTop = document.getElementById("tech-div").getBoundingClientRect( ).top;
                const expTop = document.getElementById("experience-div").getBoundingClientRect( ).top;
                const linksTop = document.getElementById("links-div").getBoundingClientRect( ).top;

                let selected = HeaderButtons.about;

                if ( linksTop < 0 ) {
                    selected = HeaderButtons.links;
                } else if ( expTop < 0 ) {
                    selected = HeaderButtons.experience;
                } else if ( techTop < 0 ) {
                    selected = HeaderButtons.tech;
                }

                if ( this.state.selected !== selected ) {
                    // I thought react was supposed to be smarter than this, but if we don't check
                    // to see if they differ first, then multiple clicks will start to cause
                    // manual scrolling to stutter on mobile, exactly why I'm not sure but it
                    // seems to be related to calling setState on each scroll. I should throttle
                    // or debounce this anyway but this seems to solve the major issue for now.
                    this.setState({ selected });
                }
            }
        } );
    }

    // ------------------------------------------------------------

    render( ) {
        return (
            <div>
                <Header
                    selected={this.state.selected}
                    options={HeaderButtons}
                    onSelected={ button => { this.select( button ) }}
                />
                <About />
                <img style={{ width : '100%' }} src={Wave} alt="" />
                <Tech />
                <Experience />
                <Portfolio />
                <Footer />
            </div>
        );
    }

    // ------------------------------------------------------------

    select( button ) {
        clearTimeout( this.state.timeout );

        const timeout = setTimeout( ( ) => {
            this.setState({ clicked : false });
            // TODO should also check scroll position here in case the user scrolled past
            // a section while clicked was true but stopped before becoming false
        }, 2000 );

        this.setState({ selected : button, clicked : true, timeout }, ( ) => {
            this.scrollTo( button + '-div' );
        });
    }

    // ------------------------------------------------------------

    scrollTo( elemId ) {
        if ( elemId === "about-div") {
            window.scrollTo({ top : 0, left : 0, behavior : 'smooth' });
        } else {
            const el = document.getElementById( elemId );
            el.scrollIntoView({ behavior : 'smooth', block : 'start'});
        }
    }

    // ------------------------------------------------------------

}

// ------------------------------------------------------------

export default App;

// ------------------------------------------------------------
